<template>
  <div class="alt-start-finish-buttons">
    <div class="btn-group">
      <!-- IDENTIFICATION -->
      <button
        v-if="allowedStates.includes('Identification')"
        class="btn btn-info"
        @click="$emit('identification')"
      >
        <font-awesome-icon
          class="mr-2"
          icon="circle"
          style="vertical-align: middle;"
        />
        {{ $t('identify') }}
      </button>

      <!-- START -->
      <button
        v-if="allowedStates.includes('Start')"
        class="btn btn-success"
        @click="$emit('start')"
      >
        <font-awesome-icon
          class="mr-2"
          icon="play"
          style="vertical-align: middle;"
        />{{ $t('start') }}
      </button>

      <!-- FINISH -->
      <button
        v-if="allowedStates.includes('Finish')"
        class="btn btn-primary"
        @click="$emit('finish')"
      >
        <font-awesome-icon
          class="mr-2"
          icon="step-forward"
          style="vertical-align: middle;"
        />
        {{ $t('finish') }}
      </button>

      <!-- ABORT -->
      <button
        v-if="allowedStates.includes('Abort')"
        class="btn btn-secondary"
        @click="$emit('abort')"
      >
        <font-awesome-icon
          class="mr-2"
          icon="stop"
          style="vertical-align: middle; color: #6f727d;"
        />
        {{ $t('abort') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartFinishButtons',
  props: {
    allowedStates: {
      type: Array,
      required: true
    }
  }
}
</script>
